import { createContext } from "react";
import IToast from "./IToast";

export interface ToasterContextType {
    toast: IToast | null;
    show: boolean;
    setToast: (toast: IToast) => void;
}

const ToasterContext = createContext<ToasterContextType>(null as unknown as ToasterContextType);

export default ToasterContext;